const defaultUserProfile = { }

export default (state = defaultUserProfile, action) => {
    switch (action.type) {
        case "SET_USER_PROFILE":
            return action.profile;
        case "RESET_USER_PROFILE":
            return defaultUserProfile;
        default:
            return state;
    }
};