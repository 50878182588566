import { createUserManager } from "redux-oidc";
import { Log } from "oidc-client";

import AuthenticatorOidcSettings from "./authenticator.oidc.settings";

Log.logger = console;
Log.level = 3;

let userManager = null;

function getUserManager() {
    if (!userManager) {
        userManager = createUserManager(AuthenticatorOidcSettings);
    }
    return userManager
}

function getExternalCredential() {
    let externalUser = localStorage.getItem("external_credentials");
    if (externalUser) return new URLSearchParams(externalUser);
    return externalUser;
}

export function setExternalCredential(value) {
    localStorage.setItem("external_credentials", value);
}

export function loggoutExternal() {
    localStorage.removeItem("external_credentials");
}

export async function isADFUserLogged() {
    let user = await getUserManager().getUser();
    if (user) {
        return true;
    }

    return false;
}

export async function isUserLoggedExpired() {
    let user = await getUserManager().getUser();
    if (user && (user.expired)) {
        return true;
    }

    return false;
}

export function isExternalUserLogged() {
    let externalUser = getExternalCredential();
    if (externalUser) {
        return true;
    }
    return false;
}

export async function isUserLogged() {
    let v_isADFUserLogged = await isADFUserLogged();
    let v_isExternalUserLogged = isExternalUserLogged();
    if (v_isADFUserLogged === true && v_isExternalUserLogged === true) {
        signoutRedirect()
        return
    }
    return v_isADFUserLogged || v_isExternalUserLogged;
}

export async function getToken() {
    if (isExternalUserLogged()) {
        return getExternalCredential().get("external_access_token");
    } else if (await isADFUserLogged()) {
        let user = await getUserManager().getUser();
        return user.access_token
    }
}

export async function signinRedirect() {
    let userManager = getUserManager();
    if (userManager) {
        await userManager.signinRedirect();
        return;
    }
    window.location.href = "/";
}

export async function signinRedirectCallback() {
    let userManager = getUserManager();
    if (userManager) {
        await userManager.signinRedirectCallback();
        return;
    }
    window.location.href = "/";
}

export async function logout() {
    loggoutExternal();
    let userManager = getUserManager();
    if (userManager) {
        await userManager.logout();
        return;
    }
    window.location.href = "/";
}

export async function signoutRedirect() {
    loggoutExternal();
    let userManager = getUserManager();
    if (userManager) {
        await userManager.signoutRedirect();
        return;
    }
    window.location.href = "/";
}