import { getToken, signinRedirect } from "services/auth/authOperations"
import { showErrorMessage } from "components/AppMessage/AppMessage";
import store from "services/localStore/store";
import createBrowserHistory from "../init/history";
import { setLockScreen } from "services/localStore/actions/appSettings";
import { CancellationToken } from "prex";

/**
 * Sends an authenticated HTTP request with application/json content.
 * @param  {url} url The web address.
 * @param  {string} [method = "GET"] The HTTP method.
 * @param  {object} [body = null] The data to send in the body as json.
 * @param  {boolean} [parseErrors = true] Parse and show response errors.
 * @param  {boolean} [lockScreen = false] Lock screen while fetching.
 * @return Promise with the response.
 */
 export const authFetchAsync = async (url, method = "GET", body = null, parseErrors = true, isScreenToLock = false, cancellationToken = CancellationToken.none, countTotalRecords = false) => {
    
    const token = await getUserTokenAsync();

    if(token === null) {
        console.log("UserInfo: User auth expired");
        var returnUrl = window.location.href;
        localStorage.setItem("returnUrl", returnUrl.search("logincallback") !== -1 ? "/" : returnUrl);
        await signinRedirect();
    }

    const state = store.getState();

    if (isScreenToLock){
        store.dispatch(setLockScreen(true));
    }

    const urlToFormat = new URL(url);

    if(method === "GET" && countTotalRecords === true) {
        urlToFormat.searchParams.append("countTotalRecords", countTotalRecords);
    }
    
    return new Promise((resolve, reject) => {
        const controller = new AbortController();
        fetch(urlToFormat, {
            method: method,
            signal: controller.signal,
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + token,
                "Boarding-Language": state.userSettings.language ? state.userSettings.language : "",
                "Timezone-Offset": -new Date().getTimezoneOffset()/60
            }),
            body: (body != null) ? JSON.stringify(body) : undefined
        }).then(function (response) {
            
            if(!response.ok) {
                if (response.status === 503) {
                    window.location.reload(true);
                    return;
                }

                if (parseErrors) {
                    parseResponseErrorAsync(response).then((msg) => {
                        showErrorMessage(msg);
                        registration.unregister();
                        reject(msg);
                    });
                }
            }
            
            if (isScreenToLock){
                store.dispatch(setLockScreen(false));
            }

            registration.unregister();
            resolve(response);
        }).catch(err => {
            // print the error (just in case) and prevent the exception from bubbling up.
            // registration.unregister();
        }); 

        // abort the request if canceled.
        const registration = cancellationToken.register(() => {                
            controller.abort();
        });
    });
};

/**
 * Sends a raw authenticated HTTP request.
 * @param  {url} url The web address.
 * @param  {string} [method = "GET"] The HTTP method.
 * @param  {object} [body = null] The data to send in the body.
 * @param  {boolean} [parseErrors = true] Parse and show response errors.
 * @param  {boolean} [lockScreen = false] Lock screen while fetching.
 * @return Promise with the response.
 */
export const authFetchRawAsync = async (url, method = "GET", body = null, parseErrors = true, isScreenToLock = false, contentType = null, cancellationToken = CancellationToken.none) => {
    
    const token = await getUserTokenAsync();

    if(token === null) {
        console.log("UserInfo: User auth expired");
        var returnUrl = window.location.href;
        localStorage.setItem("returnUrl", returnUrl.search("logincallback") !== -1 ? "/" : returnUrl);
        await signinRedirect();
    }

    const state = store.getState();

    if (isScreenToLock){
        store.dispatch(setLockScreen(true));
    }

    var headers = new Headers({
        "Authorization": "Bearer " + token,
        "Boarding-Language": state.userSettings.language ? state.userSettings.language : null,
        "Timezone-Offset": -new Date().getTimezoneOffset()/60,
    });

    if(contentType) {
        headers.append("Content-Type", contentType);
    }

    return new Promise((resolve, reject) => {

        const controller = new AbortController();
        fetch(url, {
            method: method,
            headers: headers,
            signal: controller.signal,
            body: (body != null) ? body : undefined
        }).then(function (response) {
            if(!response.ok) {
                if (response.status === 503) {
                    window.location.reload(true);
                    return;
                }

                if (parseErrors) {
                    parseResponseErrorAsync(response).then((msg) => {
                        showErrorMessage(msg);
                    });
                }
            }

            if (isScreenToLock){
                store.dispatch(setLockScreen(false));
            }

            registration.unregister();
            resolve(response);
        });

        // abort the request if canceled.
        const registration = cancellationToken.register(() => {                
            controller.abort();
        });
    });
};

/**
 * Sends a raw authenticated HTTP request.
 * @param  {component} component The react component.
 * @param  {string} dataPropertyName = "data" The state property name holding the data to send/receive.
 * @param  {string} isLoadingPropertyName = "isLoading" The state property name storing a loading indicator.
 * @param  {url} url The web address.
 * @param  {string} [method = "GET"] The HTTP method.
 * @param  {boolean} [sendAsFormData = false] Indicates if the data should be sent as FormData (default is json).
 * @return Promise with the response.
 */
export const authFetchIntoStateAsync = async (component, dataPropertyName = "data", isLoadingPropertyName = "isLoading", url, method = "GET", sendAsFormData = false) => {
    return new Promise(function(resolve, reject) {
        component.setState(() => {return { [isLoadingPropertyName]: true }}, async () => {
            let response;

            if (sendAsFormData) {
                var formData = new FormData();

                if (typeof component.state[dataPropertyName] === "object" && !(component.state[dataPropertyName] instanceof File))
                    for (var key in component.state[dataPropertyName]) formData.append(key, component.state[dataPropertyName][key])
                else
                   formData.append(dataPropertyName, component.state[dataPropertyName]);
                response = await authFetchRawAsync(url, method, formData);
            }
            else {
                response = await authFetchAsync(url, method, method === "POST" ? component.state[dataPropertyName] : null)
            }

            if (response.ok) {
                let content = await response.text();
                if (content) {
                    let data = JSON.parse(content);

                    // [Paginated lists only]
                    if (data && data.items && data.pageNumber){
                        if (component.state.data.items && component.state.data.items.length> 0 && data.pageNumber > 1) 
                        {
                            // console.log("Appending page", data.pageNumber);
                            data.items = component.state.data.items.slice(0, component.state.data.items.length - 1).concat(data.items);
                        }
                        if (data.pageNumber < data.pageCount) 
                        {
                            // console.log("Appending continuation token");
                            data.items.push(null);
                        }
                    } 

                    component.setState(() => {return {[isLoadingPropertyName]: false, [dataPropertyName]: data }}, () => resolve(response))
                }
                else {
                    component.setState(() => {return {[isLoadingPropertyName]: false}}, () => resolve(response));
                }
                // Operation Succeeded
                // if (method !== "GET") showSuccessMessage(i18next.t("msg.action.success"));
            }
            else {
                component.setState(() => {return {[isLoadingPropertyName]: false}}, () => resolve(response));
                // reject(response);
            }
        });    
    });
}

/**
 * Download a file with an authenticated HTTP request.
 * @param  {url} url The web address.
 * @return Promise.
 */
export const authDownloadAsync = async (url, isScreenToLock = false) => {

    return new Promise((resolve, reject) => {

        authFetchRawAsync(url, "GET", null, true, isScreenToLock).then(async (response) => {
            if (!response.ok) { reject(response); return }
            let blob = await response.blob();
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.href = url;
            let contentDisposition = response.headers.get("Content-Disposition");
            let fileName = "download";
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="?([^;"]+)[";]/);
                if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
            }
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
            resolve();
        });
    });
};

/**
 * Download a file with an authenticated HTTP request.
 * @param  {url} url The web address.
 * @param  {body} body of the request.
 * @return Promise.
 */
 export const authPostDownloadAsync = async (url, body, isScreenToLock = false) => {

    return new Promise((resolve, reject) => {

        authFetchRawAsync(url, "POST", body, true, isScreenToLock, "application/json").then(async (response) => {
            if (!response.ok) { reject(response); return }
            let blob = await response.blob();
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.href = url;
            let contentDisposition = response.headers.get("Content-Disposition");
            let fileName = "download";
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="?([^;"]+)[";]/);
                if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
            }
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
            resolve();
        });
    });
};

export async function getUserTokenAsync()
{
    let localToken = await getToken();
    return localToken;
}

export function parseResponseErrorAsync(response) {

    if (response.status === 403) {
        createBrowserHistory.replace("/access");
        return new Promise((resolve, reject) => {
            reject({ok: false})
        });
    }

    if (response.status === 503) {
        window.location.reload(true);
        return new Promise((resolve, reject) => {
            reject({ok: false})
        });
    }

    return new Promise((resolve, reject) => {
        response.text().then(content => {
            console.error(`${response.statusText} ${content}`);

            let json = tryParseJSON(content);            
            if (!json) {
                // Don't show HTML as an error message
                if (content.startsWith("<!DOCTYPE")) content = null;
                resolve(content || response.statusText);
            }

            // json example: {"error": [ "msg1", "msg2"], "error2": [ "msg3" ] };
            let result = "";
            for (var key in json) {
                // skip loop if the property is from prototype
                // eslint-disable-next-line no-prototype-builtins
                if (!json.hasOwnProperty(key)) continue;

                const obj = json[key];
                if (obj.constructor !== Array) continue;

                for (const s of obj) {
                    result = s.errorMessage ? s.errorMessage : (result ? `${result}\n${s}` : s);
                }
            }
            resolve(result);
        });
    });
}

function tryParseJSON(jsonString){
    try {
        var o = JSON.parse(jsonString);

        // Handle non-exception-throwing cases:
        // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
        // but... JSON.parse(null) returns null, and typeof null === "object", 
        // so we must check for that, too. Thankfully, null is falsey, so this suffices:
        if (o && typeof o === "object") {
            return o;
        }
    }
    catch (e) { console.error("authFetch tryParseJSON error", e) }

    return false;
}