import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import Loading from "pages/Shared/Loading";
import history from "services/init/history";
import { store } from "services/localStore/store";

/* global Office */

import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "@fortawesome/fontawesome-pro/css/all.min.css";
import "primereact/resources/primereact.min.css";
import "layout/sass/galaxy.scss";

// Static imports
// import AppWrapper from "./AppWrapper";
// import AppAddin from "./AppAddin";

// Dynamic imports
const AppWrapper = React.lazy(() => import("./AppWrapper"));
const AppAddin = React.lazy(() => import("./AppAddin"));

const renderApp = () => {
	ReactDOM.render(
		<Suspense fallback={<Loading />}>
			<Provider store={store}>
				<Router history={history}>
					<AppWrapper />
				</Router>
			</Provider>
		</Suspense>,
		document.getElementById("root")
	);

	// If you want your app to work offline and load faster, you can change
	// unregister() to register() below. Note this comes with some pitfalls.
	// Learn more about service workers: http://bit.ly/CRA-PWA
	serviceWorker.unregister();
}

const renderAppAddin = () => {
	ReactDOM.render(
		<Suspense fallback={<Loading showUserAgentInfo={true} />}>
			<Provider store={store}>
				<Router history={history}>
					<AppAddin />
				</Router>
			</Provider>
		</Suspense>,
		document.getElementById("root")
	);
}

/* Render application after Office initializes */
Office.onReady(function (info) {
	if (info.host !== "Word")
		renderApp();
	else 
		renderAppAddin();
});