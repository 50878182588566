import React, { useEffect, useRef } from "react";
import i18next from "i18next";
import { show } from "services/localStore/actions/appMessage";
import { store } from "services/localStore/store";
import { Toast } from "primereact/toast";
import styles from "./AppMessage.module.scss";

/** Temporarily AppMessage (prime react Growl) */
export function AppMessage(props) {
    const toast = useRef(null);

    let message = props.message;

    useEffect(() => {
        if (message) toast.current.show({ ...message, life: message.severity === "error" && 10000 });
    }, [message]);

    if (message && !message.summary) {
        switch (message.severity) {
            case "success": message.summary = i18next.t("common:msg.success.title"); break;
            case "info": message.summary = i18next.t("common:msg.information.title"); break;
            case "warn": message.summary = i18next.t("common:msg.warning.title"); break;
            case "error": message.summary = i18next.t("common:msg.error.title"); break;
            default: return;
        }
    }
    return <Toast className={styles["gx-app-message"]} ref={toast} />;
}

/** Fires a store action so that the right component with the global AppMessage component renders the message */
export function showMessage(message, summary = null) {
    return store.dispatch(show(message, "success", summary));
}
/** Fires a store action so that the right component with the global AppMessage component renders the message */
export function showSuccessMessage(message, summary = null) {
    return store.dispatch(show(message, "success", summary));
}
/** Fires a store action so that the right component with the global AppMessage component renders the message */
export function showInfoMessage(message, summary = null) {
    return store.dispatch(show(message, "info", summary));
}
/** Fires a store action so that the right component with the global AppMessage component renders the message */
export function showWarningMessage(message, summary = null) {
    return store.dispatch(show(message, "warn", summary));
}
/** Fires a store action so that the right component with the global AppMessage component renders the message */
export function showErrorMessage(message, summary = null) {
    return store.dispatch(show(message, "error", summary));
}

export default AppMessage;