const defaultTenantSettings = {
}

export default (state = defaultTenantSettings, action) => {
    switch (action.type) {
        case "SET_TENANT_SETTINGS":
            return {
                ...action.value,
                // componentSettings: JSON.parse(action.value.componentSettings)
            };        
        default:
            return state;
    }
};
