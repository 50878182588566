import { authFetchAsync } from "./authFetch";
import { apiroutes } from "../apiroutes/apiroutes"
import { isUserLogged } from "services/auth/authOperations";

/** 
 * Load user settings.
 * @param {object} user The authenticated user to fetch settings.
 */
export async function loadAuthSettingsAsync() {
    const userIsLogged = await isUserLogged();
    if (userIsLogged) {
        let response = await authFetchAsync(apiroutes.security + "usersettings");

        if (response.ok) {
            return await response.json();
        }
        else
        {
            console.log("User settings failed to load");
            return null;
        }
    }
    else {
        console.log("User settings cannot be loaded - user is not authenticated");
        return null;
    }
}

/** 
 * Save user settings.
 * @param {object} user The authenticated user to save settings.
 */
export async function saveAuthSettingsAsync(settings) {
    const userIsLogged = await isUserLogged();
    if (userIsLogged) {        
        let response = await authFetchAsync(apiroutes.security + "usersettings", "POST", settings);
        
        if (response.ok) {
            // console.log("User settings saved");
            return response.json();
        }
    }
    else {        
        console.log("No user logged in");
        return null;
    }
}

/** 
 * Save a user's component settings.
 * @param {object} settings The user's component settings to be saved.
 */
export async function saveAuthComponentSettingsAsync(settings) {
    const userIsLogged = await isUserLogged();
    if (userIsLogged) {        
        let response = await authFetchAsync(apiroutes.security + "usersettings/components", "PUT", settings);
        
        if (response.ok) {
            // console.log("User component settings saved");
            return response.json();
        }
    }
    else {        
        console.log("No user logged in");
        return null;
    }
}