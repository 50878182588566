const defaultUserMenus = {
    mainMenu: {}
}

export default (state = defaultUserMenus, action) => {
    switch (action.type) {
        case "SET_MAIN_MENU":
            return { ...state, mainMenu: action.menu };
        case "RESET_USER_PROFILE":
            return defaultUserMenus;
        default:
            return state;
    }
};