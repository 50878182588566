const locations = {
    country: null,
    state: null,
    city: null,
    parish: null,
}

export default (state = locations, action) => {
    switch (action.type) {
        case "SET_LOCATIONS":
            return action.locations;
        case "SET_LOCATION_FIELD":
            return { ...state, [action.field]: action.input };
        case "RESET_LOCATIONS":
            return locations;
        case "SET_COUNTRY":
            return { ...state, country: action.country };
        case "SET_STATE":
            return { ...state, state: action.state };
        case "SET_CITY":
            return { ...state, city: action.city };
        case "SET_PARISH":
            return { ...state, parish: action.parish };
        default:
            return state;
    }
};