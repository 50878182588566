const AppFunctions = {
	isDesktop() {
		return window.innerWidth > 896;
	},

	isMobile() {
		return window.innerWidth <= 640;
	},

	isOverlay(layoutMode) {
		return layoutMode === "overlay";
	},

	isHorizontal(layoutMode) {
		return layoutMode === "horizontal";
	},

	isSlim(layoutMode) {
		return layoutMode === "slim";
	},
	
	isStatic(layoutMode) {
		return layoutMode === "static";
	},

	toString(string, values) {
		string = values.map((key, i) => {
			return string.replace("{" + i + "}", key);
		})
		return string.toString();
	},

	toStringMultiple(string, values) {
		values.map((key, i) => {
			string = string.replace("{" + i + "}", key);
			return string;
		})
		return string.toString();
	},

	getInitials(title) {
		let name = Array.isArray(title) ? title[0] : title;
		if (!name) return "";
		name = name.trim();
		return (name.indexOf(" ") < 0) ?
			name.substring(0, 2).toUpperCase() :
			name.split(" ").map((n, i, a) => i === 0 || i + 1 === a.length ? n[0].toUpperCase() : null).join("");
	},
	
	sleep(ms) { return new Promise(resolve => setTimeout(resolve, ms)); },
	
	addDays(date, days) {
		var result = new Date(date);
		result.setDate(result.getDate() + days);
		return result;
	},
	
	arrayEquals(a, b) {
		return Array.isArray(a) &&
			Array.isArray(b) &&
			a.length === b.length &&
			a.every((val, index) => val === b[index]);
		}
}

export { AppFunctions as default }