const defaultevents = {
    eventDate: null,
    eventType: null
}

export default (state = defaultevents, action) => {
    switch (action.type) {
        case "APP_EVENT":
            return { eventDate: Date.now(), eventType: action.eventType };
        default:
            return state;
    }
};