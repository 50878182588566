const host = window.location.protocol + "//" + window.location.host;

export const apiroutes = {
    api: `${host}/api/`,
    assets: `${host}/api/assets/`,
    assetownerships: `${host}/api/assets/assetownership/`,
    batchprocesses: `${host}/api/batchprocesses/`,
    communications: `${host}/api/communications/`,
    contactbook: `${host}/api/contactbook/`,
    contractualobligationtype: `${host}/api/contractualobligationtype/`,
    consumers: `${host}/api/consumers/`,
    core: `${host}/api/core/`,
    customers: `${host}/api/customers/`,
    documentation: `${host}/api/documentation/`,
    debts: `${host}/api/debts/`,
    securitizations: `${host}/api/debts/securitization/`,
    duediligencies: `${host}/api/duediligencies/`,
    finance: `${host}/api/finance/`,
    bankaccounts: `${host}/api/finance/bankaccounts/`,
    legal: `${host}/api/legal/`,
    portfolios: `${host}/api/portfolios/`,
    reporting: `${host}/api/reporting/`,
    requests: `${host}/api/request/`,
    security: `${host}/api/security/`,
    agreements: `${host}/api/agreements/`,
    paymentrequests: `${host}/api/requests/requesttaskpayment/`,
    home: `${host}/api/home/`,
    taxdocuments: `${host}/api/finance/taxdocuments/`,
}