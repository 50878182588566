const defaultUserRights = []

export default (state = defaultUserRights, action) => {
    switch (action.type) {
        case "SET_USER_RIGHTS":
            return [
                ...action.value
            ];
        case "RESET_USER_RIGHTS":
            return defaultUserRights;
        default:
            return state;
    }
};