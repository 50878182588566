export default (state = null, action) => {
    switch (action.type) {
        case "UPDATE":
            return action.message;
        case "SHOW":
            return { ...action.message, showTask: state?.showTask };
        case "HIDE":
            return null;
        case "SHOWTASK":
            return { ...state, showTask: true, taskId: action.message.taskId, rowVersion: Date.now() }
        case "HIDETASK":
            return { ...state, showTask: false }
        default:
            return state;
    }
};