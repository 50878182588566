import { createStore, combineReducers } from "redux";
import appMessageReducer from "./reducers/appMessage.js";
import appSettingsReducer from "./reducers/appSettings.js";
import userMenusReducer from "./reducers/userMenus.js";
import eventsReducer from "./reducers/events.js";
import userProfileReducer from "./reducers/userProfile.js";
import userRightsReducer from "./reducers/userRights.js";
import userSettingsReducer from "./reducers/userSettings.js";
import userFavouritesReducer from "./reducers/userFavourites.js";
import tenantSettingsReducer from "./reducers/tenantSettings.js";
import instanceMenusReducer from "./reducers/instanceMenus.js";
import tasksBarReducer from "./reducers/tasksBar.js";
import locationsReducer from "./reducers/locations.js";
import portfolioReducer from "./reducers/portfolio.js";

export const store = createStore(
    combineReducers({
        events: eventsReducer,
        appMessages: appMessageReducer,
        appSettings: appSettingsReducer,
        userMenus: userMenusReducer,
        userProfile: userProfileReducer,
        userRights: userRightsReducer,
        userSettings: userSettingsReducer,
        userFavourites: userFavouritesReducer,
        tenantSettings: tenantSettingsReducer,
        instanceMenus: instanceMenusReducer,
        tasksBarState: tasksBarReducer,
        locations: locationsReducer,
        portfolio: portfolioReducer,
    })
);

export default store;