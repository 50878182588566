import {WebStorageStateStore} from "oidc-client";

// https://github.com/IdentityModel/oidc-client-js/wiki
export const AuthenticatorOidcSettings = {
    authority: process.env.REACT_APP_AUTH_AUTHORITY,
    client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI.replace("[origin]", window.location.origin),
    post_logout_redirect_uri: process.env.REACT_APP_AUTH_POST_LOGOUT_URI.replace("[origin]", window.location.origin),
    resource: process.env.REACT_APP_AUTH_RESOURCE,
    response_type: "id_token token",
    scope: "openid profile usercontext email user.read",
    automaticSilentRenew: true,
    accessTokenExpiringNotificationTime: 300,  // 1800, 3575, default: 60: The number of seconds before an access token is to expire to raise the accessTokenExpiring event.
    silent_redirect_uri: process.env.REACT_APP_AUTH_SILENT_URI.replace("[origin]", window.location.origin),
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    // CORS not enabled on Microsoft well known endpoints
    // https://github.com/damienbod/angular-auth-oidc-client/issues/19
    // https://github.com/IdentityModel/oidc-client-js/issues/437
    loadUserInfo: false
};

export default AuthenticatorOidcSettings;