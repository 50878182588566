import { SHOW_MESSAGE } from "../actions/appMessage";

const defaultAppMessage = {
    message: null
}

const appMessageReducer = (state = defaultAppMessage, action) => {
	switch (action.type) {
        case SHOW_MESSAGE:
            return {
                message: action.message
            };
        default:
            return state;
  }
}

export default appMessageReducer;