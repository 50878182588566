import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import animationData from "layout/images/galaxy-logo-anim.json";
import lottie from "lottie-web";
import "./Loading.module.scss";

function Loading() {
    const logoAnimContainer = useRef(null);
    const logoAnim = useRef(null);
    
    useEffect(() => {
		logoAnim.current = lottie.loadAnimation({
			container: logoAnimContainer.current,
			renderer: "svg",
			loop: true,
            autoplay: true,
			animationData: animationData
		});
		
        logoAnim.current.play();
    }, []);

    return <div className={classNames("loader-screen")}>
		<div className="loader">
			<div ref={logoAnimContainer} />
		</div>
	</div>
}

export default Loading;